.site-header {
  &__smart-menu {
    a {
      &:nth-child(2) {
        flex-basis: 27%;
      }
    }
  }
}

.spp-product-layout {
  .sticky-add-to-bag {
    &__cta {
      padding: 0 15%;
    }
  }
}
